import { isPlatformServer } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Routes } from '@angular/router';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { flattenCMSArrayObject } from '@shared/ui/utils/global-utils/flatten-array-into-object';
import { LinkDefaults } from '@store/global/types';

import { SSR_CONFIG } from '../../../../server/ssr/provider-tokens'; // req for ssr
import {
  ENV_CONFIG,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
} from '../injection-tokens';
import { LocalStorageService } from '../storage/local-storage.service';
import { WidgetsConfig } from '../widgets/types';
import {
  AppConfigGlobal,
  OktaConfig,
  PreferenceCenterConfig,
  ShoppingCartConfig,
} from './types';
import { BookerRestrictedOriginsModalWarning } from './types/booker-restricted-origins-modal-warning.type';
import { ContactUsConfig } from './types/contact-us.type';
import { ImagePaths } from './types/image-paths.type';
import {
  CONFIG_LOCALSTORAGE_KEY,
  CONFIG_LOCALSTORAGE_SYNC_ENABLE,
} from './types/mocks/app-config-constants';
import { MybConfig } from './types/myb-config';
import { Overrides } from './types/overrides';
import { SharedWebCredentialsConfig } from './types/shared-web-credentials.type';

/**
 * Wraps the application configuration values from window.__DOTCOM_ENV_CONFIG. Can also
 * contain other application wide configuration values like feature flag toggles.
 */
@Injectable({ providedIn: 'root' })
export class AppConfigService {
  overrides: Overrides;
  buildId: string;
  environment: string;
  isProdEnv: boolean;
  promoCodeAir: boolean;
  promoCodeVacation: boolean;
  cookiePolicy: string;

  widgets: WidgetsConfig;

  okta: OktaConfig;

  preferenceCenter: PreferenceCenterConfig;
  sessionCookieRedirectUrl: string;
  loginOrchestratorUrl: string;
  loginOrchestratorApiKey: string;

  contactUs: ContactUsConfig;

  sharedWebCredentials: SharedWebCredentialsConfig;

  baseDomain: string;
  baseTrueBlue: string;
  rwbBookerUrl: string;
  mobileBaseUrl: string;
  baseBook: string;
  vacationsServiceUrl: string;
  baseVacations: string;
  azApiUrl: string;
  iamServiceUrl: string;
  myTripsServiceUrl: string;
  myTripsServiceApiKey: string;
  odServiceUrl: string;
  odServiceRoutesUrl: string;
  odServiceRegionsUrl: string;
  odServiceRegionsCountriesUrl: string;
  odServiceScheduleExtensionUrl: string;
  odServiceOriginsUrl: string;
  odServiceNearbyAirportsUrl: string;
  odServiceRoutesDestinationsOriginCodeUrl: string;
  staticStorageUrl: string;
  dealsServiceUrl: string;
  flightListServiceUrl: string;
  magnoliaUrl: string;
  supportedLanguages: { [key: string]: string };
  tabletBreakPoint: number;
  mobileBreakPoint: number;
  flightStatusServiceUrl: string;
  flightStatusApiKey: string;
  bffServiceUrl: string;
  bffServiceUrlObj: {
    latest: string;
    legacy: string;
  };
  creditProfile: {
    cpProfileRead: string;
    cpProfileReadKey: string;
  };
  oktaJbWrapperServiceUrl: string;
  receiptRequestUrl: string;
  groupTravelUrl: string;
  contactUsUrl: string;
  routeMapDestinationsUrl: string;
  travelBankUrl: string;
  preferenceCenterServiceUrl: string;
  profileServiceCreditCardUrl: string;
  clm5: boolean;
  loadASAPPWidget: boolean;
  loadCaptchaWidget: boolean;
  oktaLogoutDelayTime: number;
  oauthTokens: boolean;
  whitelistedDomains: string[];
  deleteCookies: string[];
  proxyIntercepts: string[];
  deleteLocalStorage: string[];
  deleteSessionStorage: string[];
  domains: string[];
  partytown: boolean;
  lazyLoadImages: boolean;
  delayThirdPartyJS: boolean;
  imageHeightWidthAttr: boolean;
  visitThreshold: number;
  timeUnit: string;
  shortPollFlightTrackerDurationInSeconds: number;
  leanProfileUrl: string;
  leanProfileApiKey: string;
  rwbCartUrl: string;
  rwbCheckoutUrl: string;
  configUrl: string;
  displayCartInfo: boolean;
  shoppingCartUrl: string;
  rwbProfileUrl: string;
  cookieConsent: boolean;
  bffDefaultMonthsToLoad: number;
  bffAutoLoadMonths: boolean;
  bffCashUrlPointer: 'latest' | 'legacy';
  bffPointsUrlPointer: 'latest' | 'legacy';
  intersectionDebounce: number;
  intersectionRootMargin: string;
  intersectionThreshold: number;
  bookerCalendarAutoLoad: boolean;
  bookerCalendarAutoLoadDelay: number;
  bookerCalendarAutoLoadPercentInView: number;
  bookerCalendarAutoLoadRootMargin: string;
  bookerCalendarRemoveLoadOverlay: boolean;
  bookerCalendarRemoveLoadOverlayLoadingString: string;
  googleMapKey: string;
  googleMapId: string;
  shoppingCartConfig: ShoppingCartConfig;
  googleSearchUrl: string;
  opaqueProfileLookupUrl: string;
  opaqueProfileLookupApiKey: string;
  ASAPPChatInstead: string;
  ASAPPOpenOnLoadURLs: string[] = [];
  ASAPPWhitelistedPhoneNumbers: string[];
  ASAPWhiteListedURLs: string[] = [];
  authenticationEnabled: boolean;
  abTestConfigUrl: string;
  bffApiKey: string;
  purgeCdnUrl: string;
  linkDefaults: LinkDefaults;
  useBrowserUILanguageForLanguageSwitching: boolean;
  useUrlVersionDictionary: boolean;
  i18nSupportedLanguages: string[];
  i18nSupportedPersonalizations: string[];
  i18nSupportedPersonalizationsOn: boolean;
  isCMSFallbackEnabled: boolean;
  allowAllOrigins: boolean;
  blackListSitemapPaths: string[];
  isTaCEnabled: boolean;
  emScriptUrl: string;

  // vertex search
  useVertexSearch: boolean;
  vertexSearchUrl: string;

  incomingFlightDetailsUrl: string;
  isIncomingFlightDetailsEnabled: boolean;

  roundTrip: {
    showCalendarRoundtripPrices: boolean;
    defaultTripLength: number;
    serviceSuccessCashDepart: string;
    serviceSuccessPointsDepart: string;
    serviceSuccessCashReturn: string;
    serviceSuccessPointsReturn: string;
    removeLoadOverlay: boolean;
    removeLoadOverlayLoadingString: string;
  };

  // restricted modal origins
  bookerRestrictedOriginsModalWarning: BookerRestrictedOriginsModalWarning;

  // okta flags
  useIdx: boolean;
  useWidget: boolean;
  useProxy: boolean;
  useFetchInterceptor: boolean;
  useCustomWidgetValidation: boolean;
  mfaSmsDialog: boolean;
  mfaGroupList: string;
  // myb flags
  mybEmailUpdateURL: string;
  mybEnablePNRService: boolean;
  mybReplaceLastNameAccents: boolean;
  overridePWAInstallPrompt: boolean;
  enableUIDebuging: boolean;
  allowBffCaching: boolean;
  paths: ImagePaths[];

  // Credit Profile Flags
  isCpEnabled: boolean;

  jtpTopVacationDestinations: Set<string>;
  showFlightsHotelsRouteMapNative: boolean;
  showFlightsHotelsFareSaleNative: boolean;

  logPartialPageResponse: boolean;
  mybConfig: MybConfig;

  templateVersionDefaults: {
    global: string;
    cms: string;
  };

  additionalSitemapRoutes: {
    [routePath: string]: 'internal';
  };

  bookerFuzzySearch: {
    /** Number value cooresponding to enum */
    searchMode?: number;
    debounceTime?: number;
    threshold?: number;
    /** Comma separated string of property keys */
    keySelector?: string;
    ignoreCase?: boolean;
    ignoreSymbols?: boolean;
    normalizeWhitespace?: boolean;
  };

  mobileAppStoreBadges: {
    apple: {
      en: string;
      fr: string;
      es: string;
      storeUrl: string;
    };
    android: {
      en: string;
      fr: string;
      es: string;
      storeUrl: string;
    };
  };

  openDatePickerFromFareSalePage?: boolean;

  // TODO: Revisit this and all associated logic at the end of the demo/experiment phase
  useExperimentalTranslation: boolean;
  disableFRWhenRoutingToNGB: boolean;
  useLanguageInUrl: (lang: string) => boolean;
  languageInUrlEnabled: boolean;
  ignoreRoutesForLanguageInUrl: string[];
  disableHola: boolean;
  languageConfigs: {
    [key: string]: {
      urlEnabled: boolean | string;
    };
  };
  parsedSitemap: Routes;

  truncatedResponseRetries: number;

  frenchDFUrlsList: string[];

  isFeLoggerEnabled;
  feLoggerUrl;

  dynamicYield: { sectionId: string };

  private isWebComponent: InjectionTokenIsWebComponent;
  public configBase: any;
  private localStorageService: LocalStorageService;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      // the AppModule DI returns null for ENV_CONFIG
      // we need to override with AppServerModule DI
      this.isWebComponent = inject(IS_WEB_COMPONENT);
      this.configBase = inject(SSR_CONFIG);
      this.init();
    }
    // client side code
    this.isWebComponent = inject(IS_WEB_COMPONENT);
    this.configBase = inject(ENV_CONFIG);
    this.localStorageService = inject(LocalStorageService);
    this.init();
  }

  init() {
    this.environment = this.configBase.environment;
    this.isProdEnv = booleanStringCheck(this.configBase.isProdEnv);
    this.assignConfigValues();
  }

  retrieveConfigFromLocalStorage() {
    return this.localStorageService.getItem<AppConfigGlobal>(
      CONFIG_LOCALSTORAGE_KEY,
    );
  }

  syncConfigToLocalStorage(data) {
    this.localStorageService.setItem(CONFIG_LOCALSTORAGE_KEY, data);
  }

  localstorageReadFeatureEnabled() {
    const storageFlag = this.localStorageService.getItem(
      CONFIG_LOCALSTORAGE_SYNC_ENABLE,
    );
    return Boolean(storageFlag);
  }

  prepareAndPickConfigSource(): AppConfigGlobal {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      return this.configBase;
    }
    if (this.isProdEnv || !this.localstorageReadFeatureEnabled()) {
      return this.configBase;
    }

    this.changeConfig(config => {
      // Already initialized, skip.
      if (Boolean(config)) {
        return config;
      }
      // Sync config.js to localstorage.
      else {
        return this.configBase;
      }
    });

    // Storage service fails silently while logging a warning if localstorage is disabled
    // there's some other kind of error and returns undefined. Re-Check in case that happens and fall back to regular config.
    const result = this.retrieveConfigFromLocalStorage();
    return Boolean(result) ? result : this.configBase;
  }

  changeConfig(changeFn: (input: AppConfigGlobal) => AppConfigGlobal) {
    const configInitial = this.retrieveConfigFromLocalStorage();
    const configModified = changeFn(configInitial);
    this.syncConfigToLocalStorage(configModified);
  }

  // eslint-disable-next-line complexity
  assignConfigValues() {
    const config = this.prepareAndPickConfigSource();

    // List of urls that need to be prefixed with '/fr' if the language is french
    this.frenchDFUrlsList = config.flags?.apiUrlsForFrenchTranslation
      ? config.flags?.apiUrlsForFrenchTranslation
          .toString()
          .split(',')
          .map(x => config.api[x.trim()])
          .filter(x => x?.length > 0)
      : [];

    this.disableHola = booleanStringCheck(config.i18n?.disableHola || false);
    this.ignoreRoutesForLanguageInUrl = (
      config.i18n?.ignoreRoutesForLanguageInUrl || ''
    )
      .split(',')
      .filter(Boolean);

    this.i18nSupportedLanguages = (config.i18n?.i18nSupportedLanguages || '')
      .split(',')
      .filter(Boolean);

    this.i18nSupportedPersonalizations = (
      config.i18n?.i18nSupportedPersonalizations || ''
    )
      .split(',')
      .filter(Boolean);

    this.i18nSupportedPersonalizationsOn = booleanStringCheck(
      config.i18n?.i18nSupportedPersonalizationsOn || false,
    );

    this.getOverrides();
    this.buildId = config.buildId;
    this.cookiePolicy = config.cookiePolicy;

    // OKTA
    this.okta = config.okta;

    this.preferenceCenter = config.preferenceCenter;

    // Contact Us
    this.contactUs = config.contactUs;

    // Shared Web Credentials
    this.sharedWebCredentials = config.sharedWebCredentials;

    // i18n NGB API override, remove the /fr/ in the url with a separate flag
    const disableFRNGB = booleanStringCheck(
      config.i18n.disableFRWhenRoutingToNGB || false,
    );
    this.rwbBookerUrl = disableFRNGB
      ? config.api.rwbBookerUrl.replace('/fr/', '/')
      : config.api.rwbBookerUrl;

    // APIs
    this.azApiUrl = config.api.azApiUrl;
    this.iamServiceUrl = config.api.iamServiceUrl;
    this.myTripsServiceUrl = config.api.myTripsServiceUrl;
    this.myTripsServiceApiKey = config.api.myTripsServiceApiKey;
    this.odServiceUrl = config.api.odServiceUrl;
    this.odServiceRoutesUrl = config.api.odServiceRoutesUrl;
    this.odServiceRegionsUrl = config.api.odServiceRegionsUrl;
    this.odServiceRegionsCountriesUrl = config.api.odServiceRegionsCountriesUrl;
    this.odServiceScheduleExtensionUrl =
      config.api.odServiceScheduleExtensionUrl;
    this.odServiceOriginsUrl = config.api.odServiceOriginsUrl;
    this.odServiceNearbyAirportsUrl = config.api.odServiceNearbyAirportsUrl;
    this.odServiceRoutesDestinationsOriginCodeUrl =
      config.api.odServiceRoutesDestinationsOriginCodeUrl;
    this.staticStorageUrl = config.api.staticStorageUrl;
    this.dealsServiceUrl = config.api.dealsServiceUrl;
    this.flightListServiceUrl = config.api.flightListServiceUrl;
    this.baseDomain = config.api.baseDomain;
    this.baseBook = config.api.baseBook;
    this.vacationsServiceUrl = config.api.vacationsServiceUrl;
    this.baseVacations = config.api.baseVacations;
    this.baseTrueBlue = config.api.baseTrueBlue;
    this.mobileBaseUrl = config.api.mobileBaseUrl;
    this.magnoliaUrl = config.api.magnoliaUrl;
    this.flightStatusServiceUrl = config.api.flightStatusServiceUrl;
    this.flightStatusApiKey = config.api.flightStatusApiKey;
    this.bffServiceUrl = config.api.bffServiceUrl;
    this.bffServiceUrlObj = config.api.bffServiceUrlObj;
    this.creditProfile = config.api.creditProfile;
    this.bffApiKey = config.api.bffApiKey;
    this.oktaJbWrapperServiceUrl = config.api.oktaJbWrapperServiceUrl;
    this.receiptRequestUrl = config.api.receiptRequestUrl;
    this.groupTravelUrl = config.api.groupTravelUrl;
    this.contactUsUrl = config.api.contactUsUrl;
    this.routeMapDestinationsUrl = config.api.routeMapDestinationsUrl;
    this.travelBankUrl = config.api.travelBankUrl;
    this.preferenceCenterServiceUrl = config.api.preferenceCenterServiceUrl;
    this.profileServiceCreditCardUrl = config.api.profileServiceCreditCardUrl;
    this.loginOrchestratorUrl = config.api.loginOrchestratorUrl;
    this.loginOrchestratorApiKey = config.api.loginOrchestratorApiKey;
    this.sessionCookieRedirectUrl = config.api.sessionCookieRedirectUrl;
    this.oktaLogoutDelayTime = parseFloat(
      config.okta.oktaLogout.oktaLogoutDelayTime,
    );
    this.whitelistedDomains = config.okta.oktaLogout.whitelistedDomains
      .toString()
      .split(',');
    this.deleteCookies = config.okta?.oktaLogout?.deleteCookies
      ?.toString()
      ?.split(',');

    this.deleteLocalStorage = config.okta?.oktaLogout?.deleteLocalStorage
      ?.toString()
      ?.split(',');
    this.deleteSessionStorage = config.okta?.oktaLogout?.deleteSessionStorage
      ?.toString()
      ?.split(',');
    this.domains = config.okta.oktaLogout.domains.toString().split(',');
    this.oauthTokens = booleanStringCheck(config.okta.oktaLogout.oauthTokens);
    this.leanProfileUrl = config.api.leanProfileUrl;
    this.leanProfileApiKey = config.api.leanProfileApiKey;
    this.shoppingCartUrl = config.api.shoppingCartUrl;
    this.rwbProfileUrl = config.api.rwbProfileUrl;
    this.rwbCartUrl = config.api.rwbCartUrl;
    this.rwbCheckoutUrl = config.api.rwbCheckoutUrl;
    this.configUrl = config.api.configUrl;
    this.googleMapKey = config.api.googleMapKey;
    this.googleMapId = config.api.googleMapId;
    this.googleSearchUrl = config.api.googleSearchUrl;
    this.opaqueProfileLookupUrl = config.api.opaqueProfileLookupUrl;
    this.opaqueProfileLookupApiKey = config.api.opaqueProfileLookupApiKey;
    this.abTestConfigUrl = config.api.abTestConfigUrl;
    this.purgeCdnUrl = config.api.purgeCdnUrl;

    this.useExperimentalTranslation = booleanStringCheck(
      config.i18n.useExperimentalTranslation || false,
    );

    this.languageConfigs = flattenCMSArrayObject(config.i18n.languageConfigs);

    this.useLanguageInUrl = (lang: string) => {
      return (
        !!this.languageConfigs[lang] &&
        booleanStringCheck(this.languageConfigs[lang].urlEnabled)
      );
    };

    // this is different from the above function that checks to see if the language in the url is enabled
    // it instead just checks to see we are currently are in 'language in url' mode
    this.languageInUrlEnabled = booleanStringCheck(
      config.i18n.useLanguageInUrl || false,
    );

    this.disableFRWhenRoutingToNGB = booleanStringCheck(
      config.i18n.disableFRWhenRoutingToNGB || false,
    );

    this.useIdx = booleanStringCheck(config.oktaFlags?.useIdx || false);
    this.useCustomWidgetValidation = booleanStringCheck(
      config.oktaFlags?.useCustomWidgetValidation || false,
    );
    this.useWidget = booleanStringCheck(config.oktaFlags?.useWidget || false);
    this.useProxy = booleanStringCheck(config.oktaFlags?.useProxy || false);
    this.useFetchInterceptor = booleanStringCheck(
      config.oktaFlags?.useFetchInterceptor || false,
    );

    this.proxyIntercepts = config.oktaFlags?.proxyIntercepts
      ?.toString()
      ?.split(',')
      ?.map(item => item.replace(/\//g, '').trim());
    this.mfaSmsDialog = booleanStringCheck(
      config.oktaFlags?.mfaSmsDialog || false,
    );
    this.logPartialPageResponse = booleanStringCheck(
      config.flags.logPartialPageResponse || false,
    );
    this.mybConfig = config.mybConfig;
    this.shoppingCartConfig = config.shoppingCartConfig;
    this.ASAPPChatInstead = config.flags.ASAPPChatInstead;
    this.ASAPPOpenOnLoadURLs = config.ASAPP.openOnLoadURLs?.split(',') ?? [];
    this.ASAPPWhitelistedPhoneNumbers =
      config.ASAPP.ASAPPWhitelistedPhoneNumbers.toString().split(',');
    this.ASAPWhiteListedURLs = config.ASAPP.whiteListedURLs
      .toString()
      .split(',');
    this.authenticationEnabled = booleanStringCheck(
      config.ASAPP.authenticationEnabled || false,
    );
    // Widgets
    this.widgets = config.widgets;
    // Extras
    this.tabletBreakPoint = 1024;
    this.mobileBreakPoint = 768;

    this.overridePWAInstallPrompt = booleanStringCheck(
      config.flags.overridePWAInstallPrompt || false,
    );
    // BFF
    this.bffDefaultMonthsToLoad = config.BFF.defaultMonthsToLoad;
    this.bffAutoLoadMonths = booleanStringCheck(
      config.BFF.autoLoadMonths || false,
    );
    this.intersectionDebounce = +config.BFF.intersectionDebounce || 1000;
    this.intersectionRootMargin = config.BFF.intersectionRootMargin || '0px';
    this.intersectionThreshold = +config.BFF.intersectionThreshold || 0;
    this.bffCashUrlPointer = config.bookerCalendars.cashUrlPointer || 'latest';
    this.bffPointsUrlPointer =
      config.bookerCalendars.pointsUrlPointer || 'latest';

    // Booker Calendar
    this.bookerCalendarAutoLoad = booleanStringCheck(
      config.bookerCalendars.autoLoad || false,
    );
    this.bookerCalendarAutoLoadDelay =
      +config.bookerCalendars.autoLoadDelay || 1000;
    this.bookerCalendarAutoLoadRootMargin =
      config.bookerCalendars.autoLoadRootMargin || '0px';
    this.bookerCalendarAutoLoadPercentInView =
      config.bookerCalendars.autoLoadPercentInView || 0.1;
    this.bookerCalendarRemoveLoadOverlay = booleanStringCheck(
      config.bookerCalendars.removeLoadOverlay,
    );
    this.bookerCalendarRemoveLoadOverlayLoadingString =
      config.bookerCalendars.removeLoadOverlayLoadingString;

    this.roundTrip = {
      showCalendarRoundtripPrices: booleanStringCheck(
        config.bookerCalendars.showCalendarRoundtripPrices,
      ),
      defaultTripLength: +config.bookerCalendars.roundTripDefaultTripLength,
      serviceSuccessCashDepart:
        config.bookerCalendars.roundTripServiceSuccessCashDepart,
      serviceSuccessPointsDepart:
        config.bookerCalendars.roundTripServiceSuccessPointsDepart,
      serviceSuccessCashReturn:
        config.bookerCalendars.roundTripServiceSuccessCashReturn,
      serviceSuccessPointsReturn:
        config.bookerCalendars.roundTripServiceSuccessPointsReturn,
      removeLoadOverlay: booleanStringCheck(
        config.bookerCalendars.removeLoadOverlay,
      ),
      removeLoadOverlayLoadingString:
        config.bookerCalendars.removeLoadOverlayLoadingString,
    };

    // booker fuzzy search
    if (config.bookerFuzzySearch) {
      this.bookerFuzzySearch = {
        searchMode: +config.bookerFuzzySearch.searchMode,
        debounceTime: +config.bookerFuzzySearch.debounceTime,
        threshold: +config.bookerFuzzySearch.threshold,
        keySelector: config.bookerFuzzySearch.keySelector,
        // default next three configs need to default to true, cant use booleanStringCheck
        // that paring logic is done futher down the line in fuzzy service
        ignoreCase: config.bookerFuzzySearch.ignoreCase,
        ignoreSymbols: config.bookerFuzzySearch.ignoreSymbols,
        normalizeWhitespace: config.bookerFuzzySearch.normalizeWhitespace,
      };
    }

    this.mobileAppStoreBadges = {
      apple: {
        en:
          config.mobileAppStoreBadges?.apple?.en ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/App_Store_Badge_en-US.svg',
        fr:
          config.mobileAppStoreBadges?.apple?.fr ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/App_Store_Badge_fr-FR.svg',
        es:
          config.mobileAppStoreBadges?.apple?.es ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/App_Store_Badge_es-ES.svg',
        storeUrl:
          config.mobileAppStoreBadges?.apple?.storeUrl ||
          'https://apps.apple.com/ca/app/jetblue-book-manage-trips/id481370590',
      },
      android: {
        en:
          config.mobileAppStoreBadges?.android?.en ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/google-play-badge-en-US.png',
        fr:
          config.mobileAppStoreBadges?.android?.fr ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/google-play-badge-fr-FR.png',
        es:
          config.mobileAppStoreBadges?.android?.es ||
          'https://www-dev2.jetblue.com/magnoliapublic/dam/ui-assets/mobile-app/img/app-store-badges/google-play-badge-es-ES.png',
        storeUrl:
          config.mobileAppStoreBadges?.android?.storeUrl ||
          'https://play.google.com/store/apps/details?id=com.jetblue.JetBlueAndroid',
      },
    };

    // template version defaults, ex v3, v4 for cms calls
    if (config.templateVersionDefaults) {
      this.templateVersionDefaults = {
        global: config.templateVersionDefaults.global,
        cms: config.templateVersionDefaults.cms,
      };
    }

    // Shallow merging with sitemapDictionary
    this.additionalSitemapRoutes = config.additionalSitemapRoutes;

    // Images
    this.paths = config.paths;

    // JTP
    this.jtpTopVacationDestinations = new Set(
      config.JTP.topVacationDestinations.split(','),
    );
    this.showFlightsHotelsRouteMapNative = booleanStringCheck(
      config.JTP.showFlightsHotelsRouteMapNative || false,
    );
    this.showFlightsHotelsFareSaleNative = booleanStringCheck(
      config.JTP.showFlightsHotelsFareSaleNative || false,
    );

    // restricted modal origins
    this.bookerRestrictedOriginsModalWarning = {};
    // For each on restricted modal origins to convert continueButton to a boolean
    if (config.bookerRestrictedOriginsModalWarning) {
      Object.keys(config.bookerRestrictedOriginsModalWarning).forEach(key => {
        // Make sure the key will be added in upper case
        const upperCaseKey = key.toUpperCase();

        this.bookerRestrictedOriginsModalWarning[upperCaseKey] =
          config.bookerRestrictedOriginsModalWarning[key];

        this.bookerRestrictedOriginsModalWarning[upperCaseKey].forEach(
          (item, index) => {
            this.bookerRestrictedOriginsModalWarning[upperCaseKey][index] = {
              ...item,
              continueButton: booleanStringCheck(item.continueButton),
            };
          },
        );
      });
    }

    // This is done only for dev to run dev:header or dev:footer
    if (
      this.environment === 'dev' &&
      (this.isWebComponent === 'header' || this.isWebComponent === 'footer')
    ) {
      this.configUrl = this.baseDomain + this.configUrl;
    }

    this.linkDefaults = config.linkDefaults || {
      external: '',
      internal: '',
      document: '',
    };

    // flags
    this.assignFlags(config);

    this.supportedLanguages = config.supportedLanguages
      ? // transform object keys to lowercase
        Object.keys(config.supportedLanguages).reduce((obj, key) => {
          obj[key.toLowerCase().substring(0, 2)] =
            config.supportedLanguages[key];
          return obj;
        }, {})
      : {
          en: 'jetblue.com',
        };
  }

  assignFlags(config: AppConfigGlobal) {
    // Flags
    this.clm5 = booleanStringCheck(config.flags.clm5 || false);
    this.displayCartInfo = booleanStringCheck(
      config.flags.displayCartInfo || false,
    );
    this.loadASAPPWidget = booleanStringCheck(
      config.flags.loadASAPPWidget || false,
    );
    this.loadCaptchaWidget = booleanStringCheck(
      config.flags.loadCaptchaWidget || false,
    );
    this.cookieConsent = booleanStringCheck(
      config.flags.cookieConsent || false,
    );
    this.enableUIDebuging = booleanStringCheck(
      config.flags.enableUIDebuging || false,
    );
    this.allowBffCaching = booleanStringCheck(
      config.flags.allowBffCaching || false,
    );
    this.partytown = booleanStringCheck(config.flags.partytown || false);
    this.lazyLoadImages = booleanStringCheck(
      config.flags.lazyLoadImages || false,
    );

    this.delayThirdPartyJS = booleanStringCheck(
      config.flags.delayThirdPartyJS || false,
    );
    this.imageHeightWidthAttr = booleanStringCheck(
      config.flags.imageHeightWidthAttr || false,
    );
    this.shortPollFlightTrackerDurationInSeconds =
      +config.polling.shortPollFlightTrackerDurationInSeconds || 0;
    this.visitThreshold = +config.personalizations?.visitThreshold || 6;
    this.timeUnit = config.personalizations?.timeUnit || 'months';

    this.openDatePickerFromFareSalePage = booleanStringCheck(
      config.flags.openDatePickerFromFareSalePage || false,
    );
    // myb flags
    this.mybEmailUpdateURL = config.flags.mybEmailUpdateURL;
    this.mybEnablePNRService = booleanStringCheck(
      config.flags.mybEnablePNRService || false,
    );
    this.mybReplaceLastNameAccents = booleanStringCheck(
      config.flags.mybReplaceLastNameAccents || false,
    );

    this.truncatedResponseRetries = +config.flags.truncatedResponseRetries;
    this.useBrowserUILanguageForLanguageSwitching = booleanStringCheck(
      config.flags.useBrowserUILanguageForLanguageSwitching || false,
    );

    this.isCMSFallbackEnabled = booleanStringCheck(
      config.flags.isCMSFallbackEnabled,
    );
    this.allowAllOrigins = booleanStringCheck(config.flags.allowAllOrigins);

    this.useUrlVersionDictionary = booleanStringCheck(
      config.flags.useUrlVersionDictionary || false,
    );

    this.blackListSitemapPaths =
      config.flags.blackListSitemapPaths?.replace(/\s/g, '')?.split(',') || [];

    this.isTaCEnabled = booleanStringCheck(config.flags.isTaCEnabled);
    this.emScriptUrl = config.flags.emScriptUrl;

    this.isFeLoggerEnabled = booleanStringCheck(config.flags.isFeLoggerEnabled);
    this.feLoggerUrl = config.flags.feLoggerUrl;

    this.dynamicYield = config.flags.dynamicYield;

    // Credit Profile Flags
    this.isCpEnabled = booleanStringCheck(config.flags.isCpEnabled);

    this.useVertexSearch = booleanStringCheck(
      config.flags.useVertexSearch || false,
    );
    this.vertexSearchUrl = config.flags.vertexSearchUrl || '';

    this.isIncomingFlightDetailsEnabled = booleanStringCheck(
      config.flags.isIncomingFlightDetailsEnabled || false,
    );
    this.incomingFlightDetailsUrl = config.flags.incomingFlightDetailsUrl || '';
  }

  getConfigUtilities() {
    const fontSize3em = 'font-size:3em';

    const changeConfig = changeFn => {
      this.changeConfig(changeFn);
      console.warn(
        `%cChanges reflected to localstorage. Please refresh the page when you're ready to apply them application-wide.`,
        fontSize3em,
      );
    };

    return {
      changeConfig,
      purgeConfig: () => {
        this.localStorageService.removeItem(CONFIG_LOCALSTORAGE_KEY);
        console.warn(
          `%cDeleted localstorage entry for configuration parameters.`,
          fontSize3em,
        );
      },
      readFromLocalstorage: val => {
        if (val) {
          this.localStorageService.setItem(CONFIG_LOCALSTORAGE_SYNC_ENABLE, {
            flag: true,
          });
          console.warn(
            `%cWill now read config from localstorage, please refresh. Next time you want to rehydrate values from config.js, you can delete the ${CONFIG_LOCALSTORAGE_KEY} entry from localstorage and refresh the application.`,
            fontSize3em,
          );
        } else {
          this.localStorageService.removeItem(CONFIG_LOCALSTORAGE_SYNC_ENABLE);
          console.warn(
            `%cWill now read config.js as-is, please refresh. You may delete ${CONFIG_LOCALSTORAGE_KEY} from localstorage if you don't want to retain your local changes anymore.`,
            fontSize3em,
          );
        }
      },
    };
  }

  retrieveBooleanConfigValueFromLocalStorage(path: string) {
    const config = this.retrieveConfigFromLocalStorage();
    let value = '';
    if (config) value = path.split('.').reduce((a, c) => a?.[c], config);
    return booleanStringCheck(value);
  }

  getOverrides() {
    const currentConfig = this.retrieveConfigFromLocalStorage();
    if (currentConfig) {
      this.overrides = currentConfig.overrides;
    }
  }

  /**
   * This method will return the value from local storage if it exists, otherwise it will return the value from CMS
   * @param path - path to the value in the config object
   */
  getConfigValueFromLocalOrCMSSource(path: string): undefined | string {
    const config = this.retrieveConfigFromLocalStorage();
    return path
      .split('.')
      .reduce((a, c) => a?.[c], config || this.configBase || {});
  }
}
