import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppConfigService,
  CookieStorageService,
  HttpService,
} from '@core/index';
import { isSessionCpFlowEligible } from '@shared/ui/utils/global-utils/is-session-cp-flow-eligible';
import { AuthFacade } from '@store/auth/auth.facade';
import { OktaJWT } from '@store/auth/types';
import { BookProfile } from '@store/cart/types/book-profile.interface';
import { ProfileActions } from '@store/profile/profile.actions';
import { createErrorAction, networkError } from '@store/shared/action.utils';
import { StoreService } from '@store/shared/store.service';
import { NetworkError } from '@store/shared/types/network-error.type';
import { Observable, of as observableOf, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { AcceptTACResp } from 'src/app/shared/dot-tac-accept/types/dot-tac-accept.interface';
import { v4 as uuidV4 } from 'uuid';

const TAC_ENDPOINT = '/accept-terms-and-conditions';
export const TAC_SESSION_STORAGE_KEY = 'jb-tac-id';
export const REQUEST_ID_HEADER = 'x-dotcom-request-id';

@Injectable({ providedIn: 'root' })
export class ProfileApi {
  constructor(
    private http: HttpService,
    private storeService: StoreService,
    private appConfig: AppConfigService,
    private authFacade: AuthFacade,
    private cookieStorageService: CookieStorageService,
  ) {}

  private accessToken: OktaJWT;

  requestRwbProfile(
    accessToken: string,
    xAuthToken?: string,
  ): Observable<HttpResponse<BookProfile> | NetworkError> {
    const url = this.appConfig.rwbProfileUrl;
    const body: any = { jwt: accessToken };
    let headers;

    // This service does not accepts CP tokens
    if (
      !isSessionCpFlowEligible(
        accessToken,
        this.cookieStorageService,
        this.appConfig,
      )
    ) {
      if (!xAuthToken) {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
        });
      } else {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'API-Version': this.appConfig.shoppingCartConfig.apiVersion,
          'x-auth-token': xAuthToken,
        });
      }
      return this.http
        .postObserveResponse<BookProfile>(url, body, { headers })
        .pipe(
          catchError(response => {
            this.storeService.dispatchAction(
              createErrorAction(ProfileActions.RWB_PROFILE_FAILED, response),
            );
            return observableOf(networkError());
          }),
        );
    }
    return of(null);
  }

  updateProfileTAC(): Observable<HttpResponse<AcceptTACResp>> {
    this.authFacade.oktaToken
      .pipe(filter<OktaJWT>(Boolean))
      .subscribe((token: OktaJWT) => {
        this.accessToken = token;
      });
    const headers = new HttpHeaders().set(REQUEST_ID_HEADER, uuidV4());

    return this.http.postObserveResponse<AcceptTACResp>(
      TAC_ENDPOINT,
      {
        accessToken: this.accessToken,
        id: sessionStorage.getItem(TAC_SESSION_STORAGE_KEY),
      },
      { headers },
    );
  }
}
