import { B6_TYPE } from '@core/app-config/types/mocks';
import { AppConfigService } from '@core/index';
import { CookieStorageService } from '@core/storage';
import { AccessToken } from '@okta/okta-auth-js';

import { isJwtForCpUser } from './is-jwt-for-cp-user';
/**
 * Function that checks if the current flow is CP
 * @param { string } accessToken the access token
 * @param { CookieStorageService } cookieStorageService Cookie Storage Service
 * @param { AppConfigService } appConfigService App Config Service
 * @returns {boolean} true or false
 */
export function isSessionCpFlowEligible(
  accessToken: string | AccessToken,
  cookieStorageService: CookieStorageService,
  appConfigService: AppConfigService,
): boolean {
  return (
    cookieStorageService.getCookie(B6_TYPE) ===
      appConfigService?.okta?.oktaAuthType?.creditProfile &&
    appConfigService?.isCpEnabled &&
    isJwtForCpUser(accessToken)
  );
}
