import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  Inject,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppConfigService } from '@core/index';
import { DOCUMENT, WINDOW } from '@core/injection-tokens';
import { WindowService } from '@core/window-actions';
import { HeaderService } from '@shared/header/header.service';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { ProfileFacade } from '@store/profile';
import { TrueBlueProfile } from '@store/trueblue/types';
import { JbFlyoutComponent } from 'jb-component-library';
import { combineLatest, from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TrueBlueLoggedInSection } from './types/new-tb-logged-in.response.type';

/**
 * @description Logged in user and dropdown in a desktop viewport
 * @WhereIsItUsed Subnav-desktop
 */
@Component({
  selector: 'jb-tb-logged-in',
  templateUrl: './tb-logged-in.component.html',
  styleUrls: ['./tb-logged-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TbLoggedInComponent implements AfterViewInit, AfterViewChecked {
  public profile: AuthProfile;
  public profilePicture$: Observable<string>;
  public isModalOpened: boolean = false;
  public points: string;
  public isMosaic: boolean;
  public isMobile: boolean;

  private _destroy = inject(DestroyRef);

  @Input() public content: TrueBlueLoggedInSection;
  @ViewChild('flyoutSignedIn') public flyoutSignedIn: JbFlyoutComponent;

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.isModalOpened = false;
    }
  }

  constructor(
    private _authFacade: AuthFacade,
    private _profileFacade: ProfileFacade,
    private _elementRef: ElementRef,
    private _cdRef: ChangeDetectorRef,
    private _windowService: WindowService,
    private _headerService: HeaderService,
    private _appConfigService: AppConfigService,
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) public window,
  ) {
    this.profilePicture$ = this._authFacade.profilePictureBlob;

    combineLatest([
      this._authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
      this._profileFacade.profileIsLoading,
      this._profileFacade.memberProfile,
      this._windowService.isMobile,
      this._headerService.isLoggedInModalOpen$,
    ])
      .pipe(takeUntilDestroyed(this._destroy))
      .subscribe(
        ([
          authProfile,
          tbProfileIsLoading,
          tbProfile,
          isMobile,
          isLoggedInModalOpen,
        ]) => {
          this.isModalOpened = isLoggedInModalOpen;
          this.isMobile = isMobile;
          this.profile = authProfile;
          this.isMosaic = booleanStringCheck(authProfile.isMosaic);

          // prints the number of points with commas
          // get latest points from member profile only if tbProfile is available
          this.points = this._getUserPoints(tbProfile, tbProfileIsLoading);

          this._cdRef.markForCheck();
        },
      );
  }

  private _getUserPoints(
    tbProfile: TrueBlueProfile,
    tbProfileIsLoading: boolean,
  ): string | null {
    // If it is a Credit Profile user, return null points.
    if (
      this.profile.authProfileSource === 'leancreditprofile' &&
      this._appConfigService.isCpEnabled
    ) {
      return null;
    }

    // If it is a Loyalty user, return tbProfile Points or Fallback to the Profile Points
    if (!!tbProfile) {
      const curLoyalty = (tbProfile?.loyalty || []).find(
        loyalty => loyalty.membershipId === this.profile.membershipid,
      );
      return curLoyalty?.amount
        ? parseInt(curLoyalty.amount, 0).toLocaleString()
        : null;
    }

    if (!tbProfileIsLoading) {
      // fallback to points from authprofile
      return this.profile.points
        ? parseInt(this.profile.points, 0).toLocaleString()
        : null;
    }

    // Do not display anything just yet.
    return null;
  }

  public getNameInitials(): string {
    const firstInitial = this.profile?.fname?.charAt(0)?.toUpperCase();
    const lastInitial = this.profile?.lname?.charAt(0)?.toUpperCase();
    return firstInitial + lastInitial;
  }

  public ngAfterViewInit(): void {
    if (this.flyoutSignedIn) {
      from(this.flyoutSignedIn.afterOpened)
        .pipe(takeUntilDestroyed(this._destroy))
        .subscribe(() => this.handleOnFlyoutOpen());

      from(this.flyoutSignedIn.beforeClosed)
        .pipe(takeUntilDestroyed(this._destroy))
        .subscribe(() => {
          this.isModalOpened = false;
        });
    }
  }

  public ngAfterViewChecked(): void {
    const signedInTbHeight = this.document.getElementsByTagName(
      'dot-header-signed-in-dialog',
    )[0]?.offsetHeight;
    signedInTbHeight
      ? this.document.documentElement.style.setProperty(
          '--signed-in-tb-height',
          `${signedInTbHeight}px`,
        )
      : this.document.documentElement.style.setProperty(
          '--signed-in-tb-height',
          `0px`,
        );
  }

  public handleOnFlyoutOpen(): void {
    this.isModalOpened = true;
    // Make flyout scrollable on landscape mode
    const navBox = this._elementRef.nativeElement.getBoundingClientRect();
    this.document.documentElement.style.setProperty(
      '--header-desktop-height',
      `${navBox.top + navBox.height}px`,
    );
  }

  public changeModalStateOnClick() {
    this.isModalOpened = !this.isModalOpened;
    this._headerService.toggleLoggedInModal();
  }
}
