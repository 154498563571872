import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@core/injection-tokens';

import { WINDOW } from '../../core';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicYieldService {
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private envConfig: AppConfigService,
    private rendererFactory: RendererFactory2,
  ) {
    this.init();
  }
  init() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.window.DY = this.window.DY || {};
    const sectionId = this.envConfig?.dynamicYield?.sectionId;
    if (sectionId) {
      this.window.DY.userActiveConsent = { accepted: false };
      const dynamicAPITypes = ['dynamic', 'static'];
      dynamicAPITypes.forEach(apiType => {
        const srcUrl = `https://cdn.dynamicyield.com/api/${sectionId}/api_${apiType}.js`;
        const dynamicYieldScript = this.renderer.createElement('script');
        this.renderer.setAttribute(dynamicYieldScript, 'src', srcUrl);
        this.renderer.appendChild(this.document.head, dynamicYieldScript);
      });
    }
  }
  setPageContext(currentUrl: string, isHomePage: boolean) {
    const recommendationContext = isHomePage
      ? { type: 'HOMEPAGE' }
      : { type: 'OTHER', data: [{ url: currentUrl }] }; // We can make this data section anything we want
    this.window.DY.recommendationContext = recommendationContext;
  }
}
