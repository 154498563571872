import { AccessToken } from '@okta/okta-auth-js';
import jwtDecode from 'jwt-decode';
/**
 * Function that checks if access token belongs to CP users;
 * If it is not, the function should convert it into a boolean value.
 * @param { string} accessToken the access token
 * @returns {boolean} true or false
 */
export function isJwtForCpUser(accessToken: string | AccessToken): boolean {
  let decodedToken: AccessToken;
  try {
    if (typeof accessToken === 'string') {
      decodedToken = jwtDecode(accessToken);
    } else {
      decodedToken = accessToken;
    }
  } catch (_) {
    return false;
  }

  // Checks if Token Claim has NA. If true, this is a CP user.
  const claimsHasNa =
    decodedToken?.claims?.tbn === 'NA' ||
    decodedToken?.claims?.trueBlueNumber === 'NA';

  // Checks if Token Claim has True Blue Number. If true, this is a TB user.
  const claimsHasNoTrueblue = !!(
    decodedToken?.claims?.tbn || decodedToken?.claims?.trueBlueNumber
  );

  if (!claimsHasNoTrueblue || claimsHasNa) {
    return true;
  }

  return false;
}
